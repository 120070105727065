<template>
  <div class="hot-msg-list">
    <div class="hot-msg-item" v-for="(item, index) in list" :key="index" @click="detail(item)">
      <div class="msg-img"><img v-if="item.informationCoverImage" :src="item.informationCoverImage" alt="" /></div>
      <div class="msg-content">
        <div class="event">
          <div class="title">{{ item.informationTitle }}</div>
          <div class="date">{{ item.createTime.slice(0, 3).join('-') }}</div>
        </div>
        <div class="desc">
          {{ item.informationIntro }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotMsg',
  props: { list: { type: Array, default: () => [] } },
  components: {},
  data() {
    return {
      textBookInfo: []
    }
  },
  mounted() {},
  methods: {
    detail(item) {
      this.$emit('detail', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.hot-msg-list {
  .hot-msg-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0;
    cursor: pointer;
    position: relative;
    padding-left: 10px;
    &::before {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #666666;
    }
    &:nth-child(1),
    &:nth-child(2) {
      padding-left: 0;
      &::before {
        display: none;
      }
      .msg-img {
        display: block;
      }
      .msg-content {
        height: 80px;
        .desc {
          display: -webkit-box;
        }
        .event {
          margin-bottom: 5px;
          .title {
            font-weight: bold;
          }
        }
      }
    }
    .msg-img {
      width: 160px;
      height: 80px;
      overflow: hidden;
      background: #eee;
      margin-right: 30px;
      display: none;
      border-radius: 5px;
      img {
        max-width: 100%;
      }
    }
    .msg-content {
      font-size: 14px;
      flex: 1;
      color: #211f21;
      overflow: hidden;

      .event {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        .title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
        }
        .date {
          color: #666666;
        }
      }
      .desc {
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: none;
      }
    }
  }
}
</style>
