<!--
 * @Author: xiaziwen
 * @Date: 2021-01-24 16:45:23
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-01 11:14:46
 * @FilePath: \crm-education\src\views\protal\home\index.vue
-->
<template>
  <div class="protal-home">
    <div class="banner-wrap" v-if="banners.length">
      <div class="section-content">
        <a-carousel arrows>
          <div slot="prevArrow" class="custom-slick-arrow" style="left: -50px;zIndex: 1">
            <a-icon type="left-circle" />
          </div>
          <div slot="nextArrow" class="custom-slick-arrow" style="right: -50px">
            <a-icon type="right-circle" />
          </div>
          <div
            class="banner-item d-flex col justify-center align-center"
            v-for="banner in banners"
            :key="banner.bannerImageUrl"
          >
            <div class="img-wrap" @click="toBannerDetail(banner)">
              <img :src="banner.bannerImageUrl" alt="" />
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="home-nav-wrap">
      <div class="section-content py-20 px-20" >
        <a-row :gutter="20">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }" v-if="showStudentCol(domainSettings)" :class="{'widthClass':!showTeacherCol(domainSettings)}" >
            <a-card class="home-nav-card cus-card no-border shadow" :bordered="false">
              <template slot="title">
                <p class="fs-18 fw-bold text-center">学生登录</p>
              </template>
              <div class="d-flex justify-between align-center" >
                <!--domainSettings.studentDomainUrl-->
                <div
                  class="home-nav-item d-flex col justify-center align-center" v-if="isShow(domainSettings.zxXxUrl)" style="margin: auto"
                  @click="openWindow(domainSettings.zxXxUrl)"
                >
                  <div class="home-nav-item_icon icon_1"></div>
                  <p class="mt-10">在线学习平台</p>
                </div>
                <div class="col-line" v-if="isShow(domainSettings.ksUrl) && isShow(domainSettings.zxXxUrl)"></div>
                <!--http://exam.oucnet.com/ks-->
                <div
                  class="home-nav-item d-flex col justify-center align-center"
                  @click="openWindow(domainSettings.ksUrl)" v-if="isShow(domainSettings.ksUrl)" style="margin: auto"
                >
                  <div class="home-nav-item_icon icon_2"></div>
                  <p class="mt-10">易考通（在线考试系统）</p>
                </div>
                <div class="col-line"  v-if="isShow(domainSettings.zxbdUrl) && (isShow(domainSettings.ksUrl)||isShow(domainSettings.zxXxUrl))"></div>
                <div
                        class="home-nav-item d-flex col justify-center align-center"
                        @click="openWindow(domainSettings.zxbdUrl)" v-if="isShow(domainSettings.zxbdUrl)" style="margin: auto"
                >
                  <div class="home-nav-item_icon icon_2"></div>
                  <p class="mt-10">在线报读平台</p>
                </div>
              </div>
            </a-card>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }"  v-if="showTeacherCol(domainSettings)" :class="{'widthClass':!showStudentCol(domainSettings)}">
            <a-card class="home-nav-card cus-card no-border shadow" :bordered="false">
              <template slot="title">
                <p class="fs-18 fw-bold text-center">组织管理登录</p>
              </template>
              <div class="d-flex justify-between align-center">
                <!--domainSettings.managerDomainUrl-->
                <div
                  class="home-nav-item d-flex col justify-center align-center"
                  @click="openWindow(domainSettings.jwglUrl)" v-if="isShow(domainSettings.jwglUrl)" style="margin:auto"
                >
                  <div class="home-nav-item_icon icon_3"></div>
                  <p class="mt-10">教务管理平台</p>
                </div>
                <div class="col-line" v-if="isShow(domainSettings.jxglUrl) && isShow(domainSettings.jwglUrl)" ></div>
                <div
                  class="home-nav-item d-flex col justify-center align-center"
                  @click="openWindow(domainSettings.jxglUrl)" v-if="isShow(domainSettings.jxglUrl)" style="margin:auto"
                >
                  <div class="home-nav-item_icon icon_4"></div>
                  <p class="mt-10">教学管理平台</p>
                </div>
                <div class="col-line" v-if="isShow(domainSettings.zsfwUrl) &&(isShow(domainSettings.jxglUrl)||isShow(domainSettings.jwglUrl))" ></div>
                <div
                  class="home-nav-item d-flex col justify-center align-center"
                  @click="openWindow(domainSettings.zsfwUrl)" v-if="isShow(domainSettings.zsfwUrl)" style="margin:auto"
                >
                  <div class="home-nav-item_icon icon_5"></div>
                  <p class="mt-10">招生服务平台</p>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="school-main-info">
      <div class="section-content py-20 px-20">
        <a-row :gutter="20">
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <div class="school-hot-msg">
              <div class="hot-msg-head">
                <div class="msg-tab">
                  <div
                    :class="['tab', 'fs-18', leftTab === 'schoolDynamicInfo' ? 'active' : '']"
                    @click="leftTab = 'schoolDynamicInfo' " v-if="schoolDynamicInfo.length"
                  >
                    学校动态
                  </div>
                  <div
                    :class="['tab', 'fs-18', leftTab === 'schoolActionInfo' ? 'active' : '']"
                    @click="leftTab = 'schoolActionInfo'" v-if="schoolActionInfo.length"
                  >
                    校园活动
                  </div>
                </div>
                <div class="action-btn" v-if="leftTab === 'schoolDynamicInfo'&& schoolDynamicInfo.length" @click="toList('0103')">查看更多</div>
                <div class="action-btn" v-if="leftTab === 'schoolActionInfo' && schoolActionInfo.length" @click="toList('0501')">查看更多</div>
              </div>
              <div class="hot-msg-body">
                <HotMsg
                  v-if="leftTab === 'schoolDynamicInfo' && schoolDynamicInfo.length"
                  :list="schoolDynamicInfo"
                  @detail="toDetail($event, '0103')"
                ></HotMsg>
                <HotMsg
                  v-if="leftTab === 'schoolActionInfo' && schoolActionInfo.length"
                  :list="schoolActionInfo"
                  @detail="toDetail($event, '0501')"
                ></HotMsg>
                <!--<a-empty-->
                  <!--v-if="-->
                    <!--(leftTab === 'schoolDynamicInfo' && schoolDynamicInfo.length) &&-->
                      <!--(leftTab === 'schoolActionInfo' && schoolActionInfo.length)-->
                  <!--"-->
                <!--&gt;-->
                <!--</a-empty>-->
              </div>
            </div>
          </a-col>
          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <div class="school-hot-msg">
              <div class="hot-msg-head">
                <div class="msg-tab">
                  <div
                    :class="['tab', 'fs-18', rightTab === 'noticeInfo' ? 'active' : '']"
                    @click="rightTab = 'noticeInfo'" v-if="noticeInfo.length"
                  >
                    通知公告
                  </div>
                  <div
                    :class="['tab', 'fs-18', rightTab === 'teachInfo' ? 'active' : '']"
                    @click="rightTab = 'teachInfo'" v-if="teachInfo.length"
                  >
                    教学动态
                  </div>
                </div>
                <div v-if="rightTab === 'noticeInfo' && noticeInfo.length" class="action-btn" @click="toList('0401')">查看更多</div>
                <div v-if="rightTab === 'teachInfo' && teachInfo.length" class="action-btn" @click="toList('0402')">查看更多</div>
              </div>
              <div class="hot-msg-body">
                <HotMsg v-if="rightTab === 'noticeInfo' && noticeInfo.length" :list="noticeInfo" @detail="toDetail($event, '0401')"></HotMsg>
                <HotMsg v-if="rightTab === 'teachInfo' && teachInfo.length" :list="teachInfo" @detail="toDetail($event, '0402')"></HotMsg>
                <!--<a-empty-->
                  <!--v-if="-->
                    <!--(leftTab === 'noticeInfo' && noticeInfo.length) &&-->
                      <!--(leftTab === 'teachInfo' && teachInfo.length)-->
                  <!--"-->
                <!--&gt;-->
                <!--</a-empty>-->
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="school-desc" v-if="schoolProfileInfo.informationCoverImage!='' && schoolProfileInfo.informationCoverImage!=null">
      <div class="section-content py-20">
        <div class="section-title-wrap py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">学校简介</p>
            <p class="fs-24 color-gray unline">School profile</p>
          </div>
        </div>
        <div class="py-20">
          <div class="d-flex flex-wrap">
            <div class="img-wrap">
              <img :src="schoolProfileInfo.informationCoverImage" alt="图片" />
            </div>
            <div class="text-content fs-16 pl-20" v-html="schoolProfileInfo.informationIntro"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="announcements">
      <div class="section-content  py-20">
        <div class="section-title-wrap py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">通知公告</p>
            <p class="fs-24 unline">Announcements</p>
          </div>
        </div>
        <div class="announcements-process py-20">
          <div class="process-wrap">
            <div class="process-list d-flex align-item">
              <template v-for="(item, index) in noticeInfo">
                <div class="process-item" :key="index" v-if="index % 2 === 1" @click="toDetail(item, '0401')">
                  <div class="process-info p-10">
                    <div class="date color-gray">
                      <p class="fs-16">{{ item.createTime[0] }}</p>
                      <p class="fs-24">{{ item.createTime[1] }}-{{ item.createTime[2] }}</p>
                    </div>
                    <div class="detail">
                      <p class="fs-14 fw-bold mb-5 process-info-title">
                        {{ item.informationTitle }}
                      </p>
                      <div class="fs-12 line-elipsis-2" v-html="item.informationIntro"></div>
                    </div>
                  </div>
                  <div class="process-step"></div>
                  <div class="process-img">
                    <img :src="item.informationCoverImage" alt="" />
                  </div>
                </div>
                <div class="process-item" :key="index" v-else @click="toDetail(item, '0401')">
                  <div class="process-img">
                    <img :src="item.informationCoverImage" alt="" />
                  </div>
                  <div class="process-step"></div>
                  <div class="process-info">
                    <div class="date color-gray">
                      <p class="fs-16">{{ item.createTime[0] }}</p>
                      <p class="fs-24">{{ item.createTime[1] }}-{{ item.createTime[2] }}</p>
                    </div>
                    <div class="detail">
                      <p class="fs-14 fw-bold mb-5 process-info-title">
                        {{ item.informationTitle }}
                      </p>
                      <div class="fs-12 line-elipsis-2" v-html="item.informationIntro"></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="admissions" v-if="admissionsInfo.length">
      <div class="section-content  py-20">
        <div class="section-title-wrap py-15">
          <div class="section-title color-white">
            <p class="fs-32 fw-bold">招生工作</p>
            <p class="fs-24 unline color-white">Admissions</p>
          </div>
        </div>
        <div class="carousel-wrap py-20 px-10">
          <a-icon class="custom-slick-arrow button-prev" style="left: 0px;zIndex: 1" type="left-circle" />
          <a-icon class="custom-slick-arrow button-next" style="right: 0px;zIndex: 1" type="right-circle" />
          <div class="swiper-wrap">
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item, i) in admissionsInfo" :key="i">
                <div class="admissions-item mx-10">
                  <p class="fs-16 text-center mt-10 color-red">{{ item.informationTitle }}</p>
                  <div class="fs-14 admissions-item-content" v-html="item.infromationContent"></div>
                  <div class="admissions-item-button" @click="toDetail(item, '0202')"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <!-- <a-icon
            class="custom-slick-arrow"
            style="left: -40px;zIndex: 1"
            @click="$refs.admissionsCarousel.prev()"
            type="left-circle"
          />
          <a-icon
            class="custom-slick-arrow"
            style="right: -40px"
            @click="$refs.admissionsCarousel.next()"
            type="right-circle"
          />
          <a-carousel class="admissions-carousel" arrows ref="admissionsCarousel" :dots="false">
            <div
              v-for="(item, i) in admissionsInfo"
              :key="i"
              class="admissions-carousel-item d-flex justify-between align-center"
            >
              <div
                class="img-wrap mx-10"
                v-for="subItem in item"
                :key="subItem.informationCoverImage"
                @click="toDetail(subItem, '0202')"
              >
                <img :src="subItem.informationCoverImage" alt="图片" />
              </div>
            </div>
          </a-carousel> -->
        </div>
      </div>
    </div>

    <!-- <div class="school-dynamic">
      <div class="section-content  py-20">
        <div class="section-title-wrap d-flex justify-between align-center py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">学校动态</p>
            <p class="fs-24 unline">School dynamic</p>
          </div>
          <p class="fs-12 link color-gray hover-color-blue" @click="toList('0103')">
            查看更多
          </p>
        </div>
        <div class="py-20">
          <a-row type="flex">
            <a-col :md="{ span: 6 }" :xs="{ span: 24 }" v-for="(item, index) in schoolDynamicInfo" :key="index">
              <div class="ant-card-wrap" @click="toDetail(item, '0103')">
                <a-card hoverable>
                  <img slot="cover" alt="example" :src="item.informationCoverImage" />
                  <a-card-meta :title="item.informationTitle">
                    <template slot="description">
                      <div
                        class="dynamic-description line-elipsis-4 fs-12 color-gray"
                        v-html="item.informationIntro"
                      ></div>
                    </template>
                  </a-card-meta>
                </a-card>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div> -->
    <!-- <div class="campus-activities">
      <div class="section-content  py-20">
        <div class="section-title-wrap d-flex justify-between align-center py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">校园活动</p>
            <p class="fs-24 unline">Campus activities</p>
          </div>
          <p class="fs-12 link color-gray hover-color-blue" @click="toList('0501')">
            查看更多
          </p>
        </div>
        <div class="py-20 px-20">
          <a-row type="flex" :gutter="20">
            <a-col :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, index) in schoolActionInfo" :key="index">
              <div class="activity-item-wrap" @click="toDetail(item, '0501')">
                <div class="activity-item">
                  <div class="front d-flex col justify-center">
                    <div class="text-wrap">
                      <p class="fs-16 fw-bold text-center mb-10">
                        {{ item.informationTitle }}
                      </p>
                      <div class="fs-14 color-gray" v-html="item.informationIntro"></div>
                    </div>
                  </div>
                  <div
                    class="back"
                    :style="{
                      background: 'url(' + (item.informationCoverImage ? item.informationCoverImage : '') + ')'
                    }"
                  >
                    <div class="text-wrap">
                      <p class="fs-16 fw-bold color-white text-center mb-10">
                        {{ item.informationTitle }}
                      </p>
                      <div class="line-elipsis-1 fs-14 color-white" v-html="item.informationIntro"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div> -->
    <!-- <div class="news" v-if="newsInfo.length > 0">
      <div class="section-content  py-20">
        <div class="section-title-wrap d-flex justify-between align-center py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">新闻资讯</p>
            <p class="fs-24 unline">News</p>
          </div>
          <p class="fs-12 link color-gray hover-color-blue" @click="toList('0701')">
            查看更多
          </p>
        </div>
        <div class="px-20">
          <a-row :gutter="20">
            <a-col :md="{ span: 10 }" :xs="{ span: 24 }">
              <div class="p-10" @click="toDetail(item, '0701')">
                <div class="cover-wrap mb-20">
                  <img :src="newsInfo[0].informationCoverImage" alt="图片" />
                </div>
                <p class="mb-20 fs-16">{{ newsInfo[0].informationTitle }}</p>
                <div class="mb-10 fs-14 color-gray line-elipsis-4" v-html="newsInfo[0].informationIntro"></div>
              </div>
            </a-col>
            <a-col :md="{ span: 14 }" :xs="{ span: 24 }">
              <div class="news-list-wrap">
                <div class="news-list">
                  <div
                    class="news-item color-gray p-10 d-flex align-center"
                    v-for="(item, index) in newsInfo.slice(1, 4)"
                    :key="index"
                    @click="toDetail(item, '0701')"
                  >
                    <div class="news-date">
                      <p class="fs-18">{{ item.createTime[0] }}</p>
                      <p class="fs-28">{{ item.createTime[1] }}-{{ item.createTime[2] }}</p>
                    </div>
                    <div class="news-detail">
                      <p class="fs-16 mb-10 color-black">
                        {{ item.informationTitle }}
                      </p>
                      <div class="fs-14 line-elipsis-2" v-html="item.informationIntro"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div> -->
    <div class="link-about" v-if="links.length">
      <div class="section-content  py-20">
        <div class="section-title-wrap d-flex justify-between align-center py-15">
          <div class="section-title">
            <p class="fs-32 fw-bold">友情连接</p>
            <p class="fs-24 unline">Link</p>
          </div>
          <p class="fs-12 link color-gray hover-color-blue"></p>
        </div>
        <div class="py-10 px-20">
          <a-row :gutter="20">
            <a-col :md="{ span: 6 }" :xs="{ span: 24 }" v-for="(link, i) in links" :key="i">
              <a class="link-item" :href="link.linkTargetUrl" target="_blank">
                <img :src="link.linkImageUrl" alt="" />
              </a>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HotMsg from './c/HotMsg'
import api from '@/api'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  name: 'protal_home',
  components: { swiper, swiperSlide, HotMsg },
  data() {
    return {
      leftTab: '',
      //leftTab: 'schoolDynamicInfo',
      rightTab: '',
      //rightTab: 'noticeInfo',
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        loop: true,
        slidesPerView: 3,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev'
        }
      },
      organizationId: localStorage.getItem('organizationId'),
      banners: [],
      links: [],
      columns: [],
      schoolProfileInfo: {}, // 学校简介
      admissionsInfo: [], // 招生工作
      noticeInfo: [], // 通知公告
      schoolDynamicInfo: [], // 学校动态
      schoolActionInfo: [], // 校园活动
      newsInfo: [], // 新闻咨询
      teachInfo: [] // 教学动态
    }
  },
  computed: {
    ...mapState(['columnsInfo', 'domainSettings'])
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.initBanners()
      this.initLinks()
      this.initSchoolProfileInfo()
      this.initAdmissionsInfo()
      this.initNoticeInfo()
      this.initSchoolDynamicInfo()
      this.initSchoolActionInfo()
      this.initSchoolTeach()
      this.initNewsInfo()
    },
    async initBanners() {
      let res = await api.protal.getBanner({
        organizationId: this.organizationId,
        bannerType: 1,
      })
      if (res.code === 200) {
        this.banners = res.data
      }
    },
    async initLinks() {
      let res = await api.protal.getLink({
        organizationId: this.organizationId
      })
      if (res.code === 200) {
        this.links = res.data
      }
    },
    async initColumnInfo() {
      let res = await api.protal.getColumns({
        organizationId: this.organizationId
      })
      if (res.code === 200) {
        this.columns = res.data
      }
    },
    async initSchoolProfileInfo() {
      // 学校简介
      const columnInfo = this.getColumnInfoByTypeCode('0102')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.schoolProfileInfo = res.data[0]
      }
    },
    async initAdmissionsInfo() {
      // 招生工作
      const columnInfo = this.getColumnInfoByTypeCode('0202')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        // const info = []
        // res.data.forEach((item, index) => {
        //   const i = parseInt(index / 3)
        //   if (!info[i]) {
        //     info[i] = []
        //   }
        //   info[i].push(item)
        // })
        // console.log(info)
        this.admissionsInfo = res.data
      }
    },
    async initNoticeInfo() {
      // 招生工作
      const columnInfo = this.getColumnInfoByTypeCode('0401')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.noticeInfo = res.data.slice(0, 5)
        if(this.rightTab==''){
          this.rightTab = 'noticeInfo'
        }
      }
    },
    async initSchoolDynamicInfo() {
      // 学校动态
      const columnInfo = this.getColumnInfoByTypeCode('0103')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.schoolDynamicInfo = res.data.slice(0, 5)
        if(''==this.leftTab){
          this.leftTab = 'schoolDynamicInfo'
        }
      }
    },
    async initSchoolActionInfo() {
      // 校园活动
      const columnInfo = this.getColumnInfoByTypeCode('0501')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.schoolActionInfo = res.data.slice(0, 5)
        if(this.leftTab==''){
          this.leftTab = 'schoolActionInfo'
        }
      }
    },
    async initNewsInfo() {
      // 新闻
      const columnInfo = this.getColumnInfoByTypeCode('0701')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.newsInfo = res.data.slice(0, 5)
      }
    },
    async initSchoolTeach() {
      // 教学动态
      const columnInfo = this.getColumnInfoByTypeCode('0402')
      let res = await api.protal.getInfoByColumnId({
        columnsId: columnInfo.columnsId
      })
      if (res.code === 200 && res.data.length > 0) {
        this.teachInfo = res.data.slice(0, 5)
        if(this.rightTab==''){
          this.rightTab = 'teachInfo'
        }
      }
    },
    toList(key) {
      const columnInfo = this.getColumnInfoByTypeCode(key)
      this.$router.push({
        path: '/protal/list',
        query: { columnsTypeCode: columnInfo.columnsTypeCode }
      })
    },
    toBannerDetail(item) {
      window.location.href = item.bannerTargetUrl
    },
    toDetail(item, key) {
      const columnInfo = this.getColumnInfoByTypeCode(key)
      this.$router.push({
        path: '/protal/list/detail',
        query: {
          columnsTypeCode: columnInfo.columnsTypeCode,
          informationId: item.informationId,
          columnsId: item.columnsId
        }
      })
    },
    getColumnInfoByTypeCode(columnsTypeCode) {
      let info = this.columnsInfo.find(col => col.columnsTypeCode === columnsTypeCode)
      return info || {}
    },
    openWindow(url) {
      window.location.href = url
    },
    isShow(url) {
      if (url != null && url != '') {
        return true;
      }
      return false;
    },
    showStudentCol(domainSettings){
      return this.isShow(domainSettings.zxXxUrl)||this.isShow(domainSettings.ksUrl)||this.isShow(domainSettings.zxbdUrl);
    },
    showTeacherCol(domainSettings){
      return this.isShow(domainSettings.jwglUrl)||this.isShow(domainSettings.jxglUrl)||this.isShow(domainSettings.zsfwUrl);
    }
  }
}
</script>

<style lang="scss" scoped>
.protal-home {
  .section-content {
    max-width: 1200px;
    margin: 0 auto;
    .col-line {
      width: 1px;
      height: 70px;
      background-color: #999;
    }
    .section-title-wrap {
      .section-title {
        .unline {
          position: relative;
          padding-bottom: 10px;
          &::before {
            content: '';
            width: 40px;
            height: 2px;
            background-color: #fa3f3f;
            position: absolute;
            bottom: 0;
            left: 0;
          }
          &.color-white {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .banner-wrap {
    .banner-item {
      height: 560px;
      display: inline-flex !important;
      .img-wrap {
        height: 560px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .ant-carousel >>> .slick-slide {
      text-align: center;
      overflow: hidden;
    }

    .ant-carousel {
      .custom-slick-arrow {
        width: 50px;
        height: 50px;
        font-size: 50px;
        color: #666;
        opacity: 0.3;
        &::before {
          display: none;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      .slick-slide {
        h3 {
          color: #666;
          font-size: 30px;
        }
      }
    }
  }
  .home-nav-wrap {
    .home-nav-card {
    }
    .home-nav-item {
      width: 200px;
      cursor: pointer;
      .home-nav-item_icon {
        width: 60px;
        height: 60px;
        background-position: center center;
        background-repeat: no-repeat;
        &.icon_1 {
          background-image: url('../../../assets/img/protal/home-nav-1.png');
          background-size: 51px 41px;
        }
        &.icon_2 {
          background-image: url('../../../assets/img/protal/home-nav-2.png');
          background-size: 36px 40px;
        }
        &.icon_3 {
          background-image: url('../../../assets/img/protal/home-nav-3.png');
          background-size: 41px 41px;
        }
        &.icon_4 {
          background-image: url('../../../assets/img/protal/home-nav-4.png');
          background-size: 42px 42px;
        }
        &.icon_5 {
          background-image: url('../../../assets/img/protal/home-nav-5.png');
          background-size: 49px 42px;
        }
      }
    }
    .home-nav-item_3 {
      width: 140px;
      img {
        width: 40px;
      }
    }
  }
  .school-desc {
    .img-wrap {
      width: 560px;
      height: 280px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .text-content {
      flex: 1;
    }
  }
  .admissions {
    background-color: #fa3f3f;
    .swiper-container {
      padding: 50px 0;
    }
    .admissions-item {
      height: 320px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      transition: transform 0.5s ease;
      &:hover {
        transform: translateY(-50px);
      }
      .admissions-item-content {
        height: 200px;
        padding: 10px;
        overflow: hidden;
      }
      .admissions-item-button {
        width: 120px;
        height: 32px;
        border: 1px solid #fa3f3f;
        border-radius: 3px;
        margin: 10px auto;
        text-align: center;
        cursor: pointer;
        &::after {
          content: '——>';
          color: #fa3f3f;
          line-height: 27px;
        }
        &:hover {
          background-color: #fa3f3f;

          &::after {
            content: '查看详情';
            line-height: 27px;
            color: #fff;
          }
        }
      }
    }
    .carousel-wrap {
      position: relative;
      .swiper-wrap {
        padding: 0 25px;
      }
      .custom-slick-arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        font-size: 50px;
        color: #fff;
        opacity: 0.3;
        top: 50%;
        transform: translateY(-50%);
        &::before {
          display: none;
        }
        &:hover {
          opacity: 0.6;
        }
        .anticon {
        }
      }
    }
  }
  .announcements {
    .announcements-process {
      width: 100%;
      overflow: hidden;
      .process-item {
        width: 300px;
        cursor: pointer;
        &:hover {
          // border-radius: 6px;
          // box-shadow: 0px 0px 3px 3px #eee;
        }
        .process-info {
          padding: 0px 20px;
          height: 160px;
          &:hover {
            .process-info-title {
              color: #fa3f3f;
            }
          }
        }
        .process-step {
          width: 100%;
          margin: 15px 0;
          border-bottom: 1px solid #666;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #fa3f3f;
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(-50%);
          }
        }
        .process-img {
          width: 100%;
          height: 160px;
          padding: 20px;
        }
      }
    }
  }
  .school-dynamic {
    .ant-card-wrap {
      max-width: 292px;
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid #eee;
      cursor: pointer;
      .dynamic-description {
        height: 72px;
      }
    }
    ::v-deep .ant-card-cover {
      border-radius: 6px;
      overflow: hidden;
      height: 216px;
      img {
        height: 100%;
        object-fit: fill;
      }
    }
    ::v-deep .ant-card-hoverable {
      border: none;
      &:hover {
        border: none;
        background-color: #fa3f3f;
        .ant-card-body {
          .ant-card-meta-title {
            color: #fff;
          }
          .ant-card-meta-description {
            p {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .campus-activities {
    .activity-item-wrap {
      border-radius: 6px;
      overflow: hidden;
      perspective: 1000;
      width: 100%;
      height: 200px;
      transition: transform 0.5s ease;
      cursor: pointer;
      &:hover {
        .activity-item {
          transform: rotateY(180deg);
        }
      }
      .activity-item {
        position: relative;
        transition: 0.6s;
        transform-style: preserve-3d;
        .front,
        .back {
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 200px;
        }
        .front {
          z-index: 2;
          background-color: #eee;
          .text-wrap {
            padding: 20px;
          }
        }
        .back {
          transform: rotateY(180deg);
          .text-wrap {
            padding: 20px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
  .news {
    .cover-wrap {
      height: 320px;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .news-list-wrap {
      .news-item {
        border-radius: 5px;
        overflow: hidden;
        &:hover {
          background-color: #fa3f3f;
          color: #fff !important;
          box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.3);
          p {
            color: #fff !important;
          }
        }
        .news-date {
          width: 140px;
          padding: 10px;
        }
        .news-detail {
          flex: 1;
        }
      }
    }
  }
  .link-about {
    .link-item {
      display: block;
      border: 1px solid #eee;
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.school-hot-msg {
  .hot-msg-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .msg-tab {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      color: #757576;
      .tab {
        margin-right: 30px;
        position: relative;
        padding: 5px 0;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          font-weight: bold;
          color: #211f21;
          &::after {
            background: #e24042;
          }
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 64px;
          left: 50%;
          transform: translateX(-50%);
          background: #fff;
        }
      }
    }
    .action-btn {
      color: #757576;
      cursor: pointer;
    }
  }
}
.widthClass{
  width:100%
}
</style>
